import React from 'react';

function Line() {
    return(
        <>
            <div className='zk-pt-4 zk-pb-4'>
                <hr />
            </div>
        </>
    )
}

export default Line;